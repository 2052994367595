import Costruzioni from "../resources/images/servicesIcons/Construzioni.png"
import Intonaci from "../resources/images/servicesIcons/Intonaci.png"
import LavoriInQuota from "../resources/images/servicesIcons/LavoriInQuota.png"
import Pavimentazioni from "../resources/images/servicesIcons/Pavimentazioni.png"
import Pitturazioni from "../resources/images/servicesIcons/Pitturazioni.png"
import Ristrutturazioni from "../resources/images/servicesIcons/Ristrutturazioni.png"
import Potatura from "../resources/images/servicesIcons/Potatura.png"

export const servicesList = [
    {
        title: 'Costruzioni',
        icon: Costruzioni,
        iconAlt: 'Progettazione e costruzione di edifici residenziali e commerciali',
        text: 'Offriamo servizi di costruzione completi per edifici residenziali e commerciali. Dalla progettazione alla realizzazione, garantiamo qualità, sicurezza e attenzione ai dettagli in ogni fase del progetto. Realizziamo nuove costruzioni e ristrutturazioni su misura per soddisfare le vostre esigenze specifiche.'
    },
    {
        title: 'Intonaci',
        icon: Intonaci,
        iconAlt: 'Applicazione professionale di intonaci interni ed esterni',
        text: 'Forniamo servizi professionali di applicazione di intonaci interni ed esterni. Utilizziamo materiali di alta qualità per garantire una finitura impeccabile e duratura. La nostra esperienza assicura che ogni progetto, sia residenziale che commerciale, riceva la massima cura e attenzione ai dettagli.'
    },
    {
        title: 'Pavimentazioni',
        icon: Pavimentazioni,
        iconAlt: 'Soluzioni di pavimentazione di alta qualità per interni ed esterni',
        text: 'Offriamo soluzioni di pavimentazione di alta qualità per interni ed esterni. Dalla selezione dei materiali alla posa in opera, garantiamo risultati estetici e funzionali che durano nel tempo. Siamo specializzati in una vasta gamma di pavimenti, inclusi quelli in ceramica, legno, marmo e altri materiali pregiati.'
    },
    {
        title: 'Pitturazioni',
        icon: Pitturazioni,
        iconAlt: 'Pitturazione professionale per interni ed esterni',
        text: 'Forniamo servizi di pitturazione professionale per interni ed esterni. Utilizziamo vernici di alta qualità e tecniche avanzate per garantire una finitura uniforme e duratura. Che si tratti di progetti residenziali o commerciali, offriamo soluzioni personalizzate per valorizzare ogni ambiente.'
    },
    {
        title: 'Ristrutturazioni',
        icon: Ristrutturazioni,
        iconAlt: 'Trasformiamo gli spazi esistenti con soluzioni innovative e personalizzate',
        text: 'Specializzati in ristrutturazioni residenziali e commerciali, trasformiamo gli spazi esistenti con soluzioni innovative e personalizzate. Dalla progettazione alla realizzazione, garantiamo qualità, efficienza e attenzione ai dettagli per valorizzare ogni ambiente e soddisfare le vostre esigenze.'
    },
    {
        title: 'Lavori in quota',
        icon: LavoriInQuota,
        iconAlt: 'Servizi specializzati per lavori in quota con l\'uso di piattaforme aeree',
        text: 'Offriamo servizi specializzati per lavori in quota con l\'uso di piattaforme aeree fino a 20 metri. Garantiamo sicurezza e precisione in ogni intervento, sia per manutenzioni che per installazioni. Il nostro team qualificato è equipaggiato per operare in altezza in modo efficiente e conforme alle normative vigenti.'
    },
    {
        title: 'Potatura',
        icon: Potatura,
        iconAlt: 'Interventi sicuri e professionali di potatura per alberi di grande altezza',
        text: 'Specializzati in potatura in quota, offriamo interventi sicuri e professionali per alberi di grande altezza (fino a 20 metri). Utilizziamo piattaforme aeree e tecniche avanzate per garantire la salute e la forma ottimale delle piante, operando nel massimo rispetto delle normative di sicurezza.'
    }
]