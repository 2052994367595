import {Typography} from "@mui/material";
import Section from "./layout/Section";
import ScrollAnimation from "react-animate-on-scroll";

const AboutUs = () => {
    return <Section id='aboutUs' title='Chi siamo'>
        <ScrollAnimation animateOnce={true}  animateIn='zoomIn'>
            <Typography variant='body1'>
                Con anni di esperienza, siamo un punto di riferimento nel settore delle costruzioni.
                Offriamo servizi di alta qualità, dalle nuove
                costruzioni alle ristrutturazioni, intonaci,
                pavimenti, pitturazioni e servizi con
                piattaforma aerea.
            </Typography>
        </ScrollAnimation>
        <ScrollAnimation animateOnce={true}  animateIn='zoomIn'>
            <Typography variant='body1'>
                Il nostro team di professionisti qualificati lavora con dedizione per garantire la massima
                soddisfazione dei clienti, utilizzando materiali
                di prima scelta e tecnologie all'avanguardia.
                Puntiamo sulla sicurezza e la sostenibilità,
                rispettando le normative e adottando pratiche
                eco-sostenibili.
            </Typography>
        </ScrollAnimation>
        <ScrollAnimation animateOnce={true} animateIn='zoomIn'>
            <Typography variant='body1'>
                Affidatevi a noi per realizzare i vostri progetti
                con professionalità e precisione. Siamo pronti
                a offrirvi soluzioni personalizzate e innovative.
            </Typography>
        </ScrollAnimation>
    </Section>
}

export default AboutUs;