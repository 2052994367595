import {Stack, Typography, useTheme} from "@mui/material";
import ScrollAnimation from "react-animate-on-scroll";

const Section = props => {
    const {palette} = useTheme();

    return <Stack
        id={props.id}
        pt={props.noTopGap ? 0 : 20}
        pb={props.noBottomGap ? 0 : 20}
        px={props.noXGap ? 0 : {xs: props.px ? props.px.xs : 2, md: 5}}
        alignItems='center'
        justifyContent='center'>
        <Stack
            px={props.noXGap ? 0 : {xs: props.px ? props.px.xs : 1, lg: 3, xl: 5}}
            maxWidth={props.fullWidth ? '100%' : '700px'}
            width='100%'
            boxSizing='border-box'
            justifyContent='center'
            alignItems='center'
            gap={2}>
            <ScrollAnimation animateOnce={true}  animateIn='fadeInDown'>
                <Typography
                    variant='h1'
                    textAlign='center'
                    color={palette.yellow.main}
                    mb={3}
                    fontSize={{xs: '1.7rem', sm: '2rem'}}>
                    {props.title}
                </Typography>
            </ScrollAnimation>
            {props.children}
        </Stack>

    </Stack>
};

export default Section;