import Carousel from 'react-material-ui-carousel';
import {Box} from "@mui/material";
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

const ImageCarouselItem = props => {
    return <Box width='100%' height="100%">
        <img src={props.image} style={{width: '100%', height: '100%', objectFit: 'cover'}} alt={props.alt} />
    </Box>
};

const ImageCarousel = props => {
    const ItemsList = props.items.map(i => {
        return <ImageCarouselItem key={i.id} image={i.image} alt={i.alt} />
    })

    return <Carousel
        id='carousel'
        NextIcon={<ChevronRightIcon/>}
        PrevIcon={<ChevronLeftIcon/>}
        height='calc(100dvh - 70px)'
        stopAutoPlayOnHover={false}
        navButtonsAlwaysVisible={true}
        indicatorIconButtonProps={{
            style: {
                top: -50,
                zIndex: 100
            }
        }}
        sx={{
            width: '100%'
        }}
    >
        {ItemsList}
    </Carousel>
};

export default ImageCarousel;