import Section from "./layout/Section";
import {Button, Grid, TextField, Typography, useTheme} from "@mui/material";
import SocialMenu from "./SocialMenu";
import CustomTextField from "./_atoms/CustomTextField";
import {useEffect, useRef} from "react";
import ScrollAnimation from "react-animate-on-scroll";
import useFetch from "../hooks/useFetch";

const ContactForm = () => {
    const nameInputRef = useRef();
    const lastnameInputRef = useRef();
    const emailInputRef = useRef();
    const phoneInputRef = useRef();
    const messageInputRef = useRef();

    const {makeRequest, response} = useFetch();

    useEffect(() => {
        if (response){

        }
    }, [response]);


    const onSubmit = (event) => {
        event.preventDefault();

        makeRequest('https://www.antoniocoluccia.eu/backend/sendEmail.php', 'POST', {
            message: messageInputRef.current.value,
            name: nameInputRef.current.value,
            lastname: lastnameInputRef.current.value,
            email: emailInputRef.current.value,
            phone: phoneInputRef.current.value
        })
    }

    return <form style={{width: '100%'}} onSubmit={onSubmit}>
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <ScrollAnimation animateOnce={true} animateIn='zoomIn'>
                    <CustomTextField inputRef={emailInputRef} label="Email" variant="outlined" />
                </ScrollAnimation>
            </Grid>
            <Grid item xs={12}>
                <ScrollAnimation animateOnce={true} animateIn='zoomIn'>
                    <CustomTextField inputRef={nameInputRef} label="Nome" variant="outlined" />
                </ScrollAnimation>
            </Grid>
            <Grid item xs={12}>
                <ScrollAnimation animateOnce={true} animateIn='zoomIn'>
                    <CustomTextField inputRef={lastnameInputRef} label="Cognome" variant="outlined" />
                </ScrollAnimation>
            </Grid>
            <Grid item xs={12}>
                <ScrollAnimation animateOnce={true} animateIn='zoomIn'>
                    <CustomTextField inputRef={phoneInputRef} label="Telefono" variant="outlined" />
                </ScrollAnimation>
            </Grid>
            <Grid item xs={12}>
                <ScrollAnimation animateOnce={true} animateIn='zoomIn'>
                    <CustomTextField inputRef={messageInputRef} multiline minRows={5} maxRows={5} label="Messaggio" variant="outlined" />
                </ScrollAnimation>
            </Grid>
            <Grid item xs={9}>

            </Grid>
            <Grid item xs={3}>
                <ScrollAnimation animateOnce={true} animateIn='zoomIn'>
                    <Button type='submit' variant='contained' color='blue' size='large' sx={{fontWeight: 'bold'}} fullWidth >INVIA</Button>
                </ScrollAnimation>
            </Grid>
        </Grid>
    </form>
}

const Contacts = () => {
    return <Section id='contacts' title='Contatti'>
        <Typography textAlign='center' variant='h4' mb={2}>
            <ScrollAnimation animateOnce={true} animateIn='fadeIn'>
                Contattaci sui social
            </ScrollAnimation>
        </Typography>
        <SocialMenu animated />
        <Typography textAlign='center' variant='h4' mt={5} mb={2}>
            <ScrollAnimation animateOnce={true} animateIn='fadeIn'>
                o scrivici una mail
            </ScrollAnimation>
        </Typography>
        <ContactForm />
    </Section>
}

export default Contacts;