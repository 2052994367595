import useFetch from "../hooks/useFetch";
import {useEffect, useState} from "react";
import Section from "./layout/Section";
import ImageCarousel from "./_atoms/ImageCarousel";
import {useLoad} from "../store/LoadProvider";

const Gallery = () => {
    const {makeRequest, response} = useFetch();
    const [galleryItems, setGalleryItems] = useState([]);

    const load = useLoad();

    useEffect(() => {
        makeRequest('https://www.antoniocoluccia.eu/wp-json/wp/v2/media','get');
    }, []);

    useEffect(() => {
        if (response) {
            const images = Object.values(response).filter(item => item.media_type === 'image');
            const imagesList = images.map(img => {
                return {
                    id: img.id,
                    alt: img.alt_text,
                    image: img.source_url
                }
            });
            setGalleryItems(imagesList);

            load.hideLoader();
        }
    }, [response]);

    return <Section id='gallery' title='Le nostre opere' fullWidth px={{xs: 0, lg: 3, xl: 5}} noBottomGap>
        {galleryItems.length > 0 && <ImageCarousel items={galleryItems} />}
    </Section>
}

export default Gallery;