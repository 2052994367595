import {Grid, Stack, Typography, useTheme} from "@mui/material";
import Logo from "../resources/images/logo_vertical.png";
import {menuItems} from "../data/menuItems";
import {animateScroll, scroller} from "react-scroll";
import SocialMenu from "./SocialMenu";

const Footer = () => {
    const {palette} = useTheme();
    const onClick = (menuItem) => {
        scroller.scrollTo(menuItem, {
            duration: 600,
            smooth: true,
            offset: (menuItem === 'menu') ? 0 : -70
        })
    }

    return <Stack alignItems='center' justifyContent='center' px={2} pt={10} sx={{backgroundColor: palette.deepPurple.dark}} mt={10}>
        <Stack maxWidth={1200} width='100%'>
            <Grid container alignItems='center' sx={{alignItems: 'flex-start'}}>
                <Grid item px={2} xs={12} sm={4} mb={8}>
                    <Stack justifyContent='center' alignItems='center'>
                        <img src={Logo} alt='Antonio Coluccia - logo' style={{marginTop: '-40px'}} height={200} />
                        <Typography variant='body1' textAlign='center' color={palette.blue.main} mb={1} fontSize='.9rem'>via Martiri D'Otranto, 105/A</Typography>
                        <Typography variant='body1' textAlign='center' color={palette.blue.main} mb={1} fontSize='.9rem'>73040 - Supersano (LE)</Typography>
                        <Typography variant='body1' textAlign='center' color={palette.blue.main} mb={1} fontSize='.9rem'>328 4604922 - ant.coluccia@libero.it</Typography>
                        <Typography variant='body1' textAlign='center' color={palette.blue.main} mb={1} fontSize='.9rem'>P.IVA 03861140758</Typography>
                        <Typography variant='body1' textAlign='center' color={palette.blue.main} fontSize='.9rem'>CF CLCNTN67A24L008W </Typography>
                    </Stack>
                </Grid>
                <Grid item px={2} xs={12} sm={4} mb={8}>
                    <Typography variant='body1' textAlign='center' color={palette.yellow.main} mb={2} fontWeight='bold' fontSize='1.3rem'>Menu</Typography>
                    <Typography variant='body1' sx={{cursor: 'pointer'}} onClick={() => animateScroll.scrollToTop({duration: 600, smooth: true})} textAlign='center' color={palette.white.main} mb={1} fontSize='1.1rem'>Home</Typography>
                    {menuItems.map((link) => (
                        <Typography key={link.target} variant='body1' sx={{cursor: 'pointer'}} onClick={() => onClick(link.target)} textAlign='center' color={palette.white.main} mb={1} fontSize='1.1rem'>{link.title}</Typography>
                    ))}
                </Grid>
                <Grid item px={2} xs={12} sm={4} mb={8}>
                    <Typography variant='body1' textAlign='center' color={palette.yellow.main} mb={2} fontWeight='bold' fontSize='1.3rem'>I nostri social</Typography>
                    <SocialMenu size={50} />
                </Grid>
            </Grid>
        </Stack>
        <Typography py={1} sx={{backgroundColor: palette.deepPurple.dark}} variant='body1' textAlign='center' color={palette.deepPurple.light} fontSize='.9rem' >Designed by <a style={{color: 'inherit'}} target='_blank' href='https://www.lucameledev.it' rel="noreferrer">lucameledev.it</a></Typography>
    </Stack>
}

export default Footer;