import HeroImage from "./HeroImage";
import Navigation from "./Navigation";
import {Parallax} from "react-parallax";
import parallax0 from "../resources/images/parallax_0.jpg";
import AboutUs from "./AboutUs";
import parallax1 from "../resources/images/parallax_1.jpg";
import Services from "./Services";
import parallax2 from "../resources/images/parallax_2.jpg";
import Gallery from "./Gallery";
import Contacts from "./Contacts";
import Footer from "./Footer";
import Loader from "./Loader";

const Home = () => {
    return <>
        <Loader isLoading />
        <>
            <HeroImage/>
            <Navigation/>
            <Parallax
                bgImage={parallax0}
                bgImageAlt={"Antonio Coluccia - Impresa edile in Supersano - Lecce"}
                bgImageStyle={{
                    width: '100%',
                    height: '100%',
                    objectFit: 'cover'
                }}
                strength={200}
                style={{height: '100dvh'}}
            />
            <AboutUs />
            <Parallax
                bgImage={parallax1}
                bgImageAlt={"Antonio Coluccia - Costruzioni, Intonaci, Pavimentazioni, Piturazioni, Ristrutturazioni, Lavori in quota e Potatura."}
                bgImageStyle={{
                    width: '100%',
                    height: '100%',
                    objectFit: 'cover'
                }}
                strength={200}
                style={{height: '100dvh'}}
            />
            <Services />
            <Parallax
                bgImage={parallax2}
                bgImageAlt={"Antonio Coluccia - Costruzioni, Intonaci, Pavimentazioni, Piturazioni, Ristrutturazioni, Lavori in quota e Potatura."}
                bgImageStyle={{
                    width: '100%',
                    height: '100%',
                    objectFit: 'cover'
                }}
                strength={200}
                style={{height: '100dvh'}}
            />
            <Gallery />
            <Contacts />
            <Footer />
        </>
    </>
}

export default Home;