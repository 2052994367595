import {Stack, useTheme} from "@mui/material";
import FacebookIcon from '@mui/icons-material/FacebookRounded';
import InstagramIcon from '@mui/icons-material/Instagram';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import ScrollAnimation from "react-animate-on-scroll";

const SocialMenu = props => {
    const {palette} = useTheme();
    const facebookLink = 'https://www.facebook.com/costruzioniedili.antoniocoluccia';
    const instagramLink = 'https://www.instagram.com/antoniocoluccia.eu/';
    const whatsappLink = 'https://wa.me/393284604922';
    const defaultHeight = 60;

    const iconsStyle = [
        {
            fontSize: props.size ? props.size : defaultHeight,
            color: palette.blue.main,
        },
        {
            '&:hover': {
                color: palette.blue.light
            }
        }
    ]

    return <Stack alignItems='center' justifyContent='center' flexDirection='row' gap={props.gap !== undefined ? props.gap : 3}>
        <a target='_blank' href={whatsappLink} rel="noreferrer">
            {props.animated && <ScrollAnimation animateOnce={true} animateIn='zoomIn' >
                <WhatsAppIcon sx={iconsStyle} />
            </ScrollAnimation>}
            {!props.animated && <WhatsAppIcon sx={iconsStyle} />}
        </a>
        <a target='_blank' href={facebookLink} rel="noreferrer">
            {props.animated && <ScrollAnimation animateOnce={true} delay={150} animateIn='zoomIn' >
                <FacebookIcon sx={iconsStyle} />
            </ScrollAnimation>}
            {!props.animated &&  <FacebookIcon sx={iconsStyle} />}
        </a>
        <a target='_blank' href={instagramLink} rel="noreferrer">
            {props.animated && <ScrollAnimation animateOnce={true} delay={300} animateIn='zoomIn' >
                <InstagramIcon sx={iconsStyle} />
            </ScrollAnimation>}
            {!props.animated && <InstagramIcon sx={iconsStyle} />}
        </a>
    </Stack>
}

export default SocialMenu