import Section from "./layout/Section";
import {servicesList} from "../data/servicesList";
import {Grid, Stack, Typography} from "@mui/material";
import ScrollAnimation from "react-animate-on-scroll";

const ServiceItem = props => {
    return <Grid item xs={12} lg={6} px={{xs: 0, md: 6, xl: 12}} py={5}>
        <Stack alignItems='center' justifyContent='center'>
            <ScrollAnimation animateOnce={true} animateIn='fadeInDown'>
                <Stack alignItems='center' justifyContent='center' mb={1}>
                    <img src={props.icon} width={100} alt={props.iconAlt} />
                </Stack>
            </ScrollAnimation>
            <Typography variant='h3' mb={4} noWrap={true}>
                <ScrollAnimation animateOnce={true} animateIn='zoomIn'>
                    {props.title}
                </ScrollAnimation>
            </Typography>
            <ScrollAnimation animateOnce={true} animateIn='zoomIn'>
                <Typography variant='body1' textAlign='left'>
                    {props.text}
                </Typography>
            </ScrollAnimation>
        </Stack>
    </Grid>
}

const Services = () => {
    const services = servicesList.map((sl, index) => {
        return <ServiceItem key={sl.title} {...sl} />
    })

    return <Section id='services' title="I nostri servizi" fullWidth>
        <Stack alignItems='center' justifyContent='center' mb={4}>
            <ScrollAnimation animateIn='fadeIn'>
                <Typography variant='body1' maxWidth={700}>
                    Offriamo una gamma completa di servizi per soddisfare tutte le vostre esigenze di costruzione e manutenzione. Dalla realizzazione di nuovi edifici alle ristrutturazioni, dagli intonaci alle pavimentazioni, fino ai lavori in quota e alla potatura, il nostro team di esperti garantisce qualità, sicurezza e attenzione ai dettagli in ogni intervento. Siamo dedicati a fornire soluzioni su misura, utilizzando materiali di alta qualità e tecniche all'avanguardia per assicurare risultati duraturi e di eccellenza.
                </Typography>
            </ScrollAnimation>
        </Stack>
        <Grid container maxWidth={1200} justifyContent='center'>
            {services}
        </Grid>
    </Section>
}

export default Services;