import {
    Box, Button, Divider, Drawer,
    IconButton,
    List,
    ListItem,
    ListItemButton,
    ListItemText, Stack, Typography, useTheme,
} from "@mui/material";
import logo from "../resources/images/logomark.png";
import verticalLogo from "../resources/images/logo_vertical.png"
import MenuIcon from '@mui/icons-material/Menu';
import {scroller, animateScroll} from "react-scroll";
import {useEffect, useState} from "react";
import {menuItems} from "../data/menuItems";
import SocialMenu from "./SocialMenu";

const Navigation = () => {
    const [mobileMenuState, setMobileMenuState] = useState(false);
    const [menuVisible, setMenuVisibile] = useState(false);
    const {palette} = useTheme();

    useEffect(() => {
        if (window.scrollY > window.innerHeight - 100){
            setMenuVisibile(true);
        }

        document.addEventListener('scroll', () => {
            if (window.scrollY > window.innerHeight){
                setMenuVisibile(true);
            } else {
                setMenuVisibile(false);
            }
        })
    }, []);

    const toggleDrawer = (open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        setMobileMenuState(open);
    };

    const mobileMenu = () => {
        const onClick = (menuItem) => {
            scroller.scrollTo(menuItem, {
                duration: 600,
                smooth: true,
                offset: (menuItem === 'menu') ? 0 : -70
            })
        }

        return <>
            <Stack
                id='navigation-bar'
                justifyContent='center'
                alignItems='center'
                direction="row"
                width="100%"
                height='70px'
                boxSizing='border-box'
                sx={{
                    px: '10px',
                    background: palette.deepPurple.main,
                }}
            >

                <Box position='absolute' left={10}>
                    <IconButton
                        onClick={toggleDrawer(true)}
                        edge="start"
                        color='brLight'
                        aria-label="menu"
                        sx={{ fontSize: '2.5rem', m: 0, p: 1, pl: 0 }}
                    >
                        <MenuIcon sx={{color: palette.pink.main}} fontSize='3.5rem' />
                    </IconButton>
                </Box>
                <Box onClick={() => animateScroll.scrollToTop({duration: 600, smooth: true})} sx={{flexGrow: 1, textAlign: 'center'}}>
                    <img src={logo} alt="Logo impresa edile Antonio Coluccia - Provincia di Lecce" style={{height: 35}} />
                </Box>
            </Stack>

            <Drawer
                anchor="left"
                open={mobileMenuState}
                onClose={toggleDrawer(false)}
                PaperProps={{
                    sx: {
                        background: palette.deepPurple.main
                    }
                }}
            >
                <Stack
                    justifyContent='center'
                    alignItems='center'
                    sx={{
                        width: '100%',
                        height: '200px'
                    }}>
                    <img src={verticalLogo} alt="Logo impresa edile Antonio Coluccia - Supersano - Provincia di Lecce" style={{height: 130}} />
                </Stack>
                <Divider variant='middle' color={palette.deepPurple.light} />
                <Box
                    sx={{width:250}}
                    role="presentation"
                    onClick={toggleDrawer(false)}
                    onKeyDown={toggleDrawer(false)}
                >
                    <List>
                        {menuItems.map((link) => (
                            <ListItem key={link.title} disablePadding>
                                <ListItemButton onClick={() => onClick(link.target)}>
                                    <ListItemText primary={link.title} />
                                </ListItemButton>
                            </ListItem>
                        ))}
                    </List>
                </Box>
                <Divider variant='middle' color={palette.deepPurple.light} />
                <Box mt={6}>
                    <SocialMenu size={40} noAnimation={true} />
                </Box>
            </Drawer>
        </>
    }

    const desktopMenu = () => {
        const onClick = (menuItem) => {
            scroller.scrollTo(menuItem, {
                duration: 600,
                smooth: true,
                offset: 0
            })
        }

        return <>
            <Stack
                id='navigation-bar'
                justifyContent='center'
                alignItems='center'
                direction="row"
                width="100%"
                height='70px'
                boxSizing='border-box'
                sx={{
                    px: '50px',
                    background: palette.deepPurple.main
                }}
            >
                <Box onClick={() => animateScroll.scrollToTop({duration: 600, smooth: true})} sx={{flexGrow: 1, textAlign: 'left'}}>
                    <img src={logo} alt="Logo impresa edile Antonio Coluccia - Supersano" style={{height: 35}} />
                </Box>


                <Box sx={{ display: 'flex'}} mr={3}>
                    {menuItems.map((link) => (
                        <Button key={link.title} onClick={() => onClick(link.target)}>
                            <Typography variant='body1' fontSize='1rem'>{link.title}</Typography>
                        </Button>
                    ))}
                </Box>
                <SocialMenu gap={2} size={30} />
            </Stack>

        </>
    }

    return <>
        <Stack
            position='sticky'
            maxWidth='100vw'
            sx={{
                transition: 'all ease-out .3s',
                height: menuVisible ? 70 : 0,
                zIndex: 101,
                top: 0,
                display: {
                    xs: 'none',
                    md: 'flex'
                },
                width: '100%',
                transform: menuVisible ? 'scaleY(1)' : 'scaleY(0)',
                transformOrigin: menuVisible ? 'top' : 'top',
                opacity: menuVisible ? 1 : 0
            }}>
            {desktopMenu()}
        </Stack>
        <Stack
            position='sticky'
            maxWidth='100vw'
            sx={{
                transition: 'all ease-out .3s',
                height: menuVisible ? 70 : 0,
                zIndex: 101,
                top: 0,
                display: {
                    xs: 'flex',
                    md: 'none'
                },
                width: '100%',
                transform: menuVisible ? 'scaleY(1)' : 'scaleY(0)',
                transformOrigin: menuVisible ? 'top' : 'top',
                opacity: menuVisible ? 1 : 0
            }}>
            {mobileMenu()}
        </Stack>
    </>;
};

export default Navigation;