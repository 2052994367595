import './App.css';
import {ThemeProvider} from "@mui/material";
import customTheme from "./config/customTheme";
import LoadProvider from "./store/LoadProvider";
import Home from "./components/Home";

function App() {
    return (
        <ThemeProvider theme={customTheme}>
           <LoadProvider>
               <Home />
           </LoadProvider>
        </ThemeProvider>
    );
}

export default App;
