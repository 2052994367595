import { withStyles, withTheme} from "@mui/styles";
import { TextField } from "@mui/material";


const styles = theme => ({
    customTextField: {
        width: '100%',
        '& label': {
            color: theme.palette.yellow.main,
        },
        '& label.Mui-focused': {
            color: theme.palette.yellow.main
        },
        '&:hover label': {
            color: theme.palette.yellow.light
        },
        '& input':{
            color: theme.palette.white.main
        },
        '& textarea':{
            color: theme.palette.white.main
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: theme.palette.blue.main
            },
            '&.Mui-focused fieldset': {
                borderColor: theme.palette.blue.main
            },
            '&:hover fieldset': {
                borderColor: theme.palette.blue.light
            }
        },
    },
});

const CustomTextField = (props) => {
    const { classes, theme, ...other } = props;

    return <TextField className={classes.customTextField} {...other} />
}

export default withTheme(withStyles(styles)(CustomTextField));