import {Stack, useTheme} from "@mui/material";
import logo from "../resources/images/logo_vertical.png";
import heroBackground from "../resources/images/hero_background.png";
import {useEffect, useRef, useState} from "react";
import {useLoad} from "../store/LoadProvider";

const HeroImage = () => {
    const {palette} = useTheme();
    const load = useLoad();
    const [backgroundOpacity, setBackgrounOpacity] = useState(0);
    const [logoOpacity, setLogoOpacity] = useState(0);
    const [circleSize, setCircleSize] = useState(0);

    useEffect(() => {
        if (!load.isLoading){
            setTimeout(() => setBackgrounOpacity(1), 600)
        }
    }, [load.isLoading]);

    const onBackgroundTransitionEnd = () => {
        console.log(logoOpacity)
        setCircleSize(300);
    }

    const onCircleTransitionEnd = () => {
        setLogoOpacity(1);
    }

    return <Stack onTransitionEnd={onBackgroundTransitionEnd} height='100dvh' sx={{
        backgroundColor: palette.dark.main,
        backgroundImage: `url(${heroBackground})`,
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        animationName: 'Background-translate',
        animationDuration: '500s',
        animationIterationCount: 'infinite',
        animationTimingFunction: 'linear',
        transition: 'opacity ease-out .5s',
        opacity: backgroundOpacity
    }}>
        <Stack height='100dvh' alignItems='center' justifyContent='center' bgcolor='#00000060'>
            <Stack
                width={circleSize}
                height={circleSize}
                borderRadius='50%'
                alignItems='center'
                justifyContent='center'
                bgcolor={palette.dark.main}
                onTransitionEnd={onCircleTransitionEnd}
                sx={{
                    transition: 'all ease-out .8s',
                    boxShadow: '0 0 20px black'
                }}
            >
                <img
                    src={logo}
                    height='230px'
                    style={{
                        opacity: logoOpacity,
                        transition: 'opacity 2s linear 500ms'
                    }}
                />
            </Stack>
        </Stack>
    </Stack>
}

export default HeroImage;