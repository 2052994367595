import {useState} from "react";
import axios from 'axios';

const useFetch = () => {
    const [response, setResponse] = useState(null);
    const [isFetching, setIsFetching] = useState(false);

    const makeRequest = (url, method, data) => {
        setIsFetching(true);
        setResponse(null);

        const formData = new FormData();

        formData.append('data', JSON.stringify({
            requestData: data,
        }))

        axios({
            url,
            data: formData,
            method: method
        })
            .then((res) => {
                setIsFetching(false);
                setResponse({...res.data});
            })
            .catch((err) => {
                setIsFetching(false);
                setResponse({
                    state: 'error',
                    error: err
                });
            })
    }

    return {response, isFetching, makeRequest};
}

export default useFetch;