import {Box, Stack, Typography, useTheme} from "@mui/material";
import Carousel from "react-material-ui-carousel";

import Costruzioni from '../resources/images/servicesIcons/Construzioni.png';
import Intonaci from '../resources/images/servicesIcons/Intonaci.png';
import LavoriInQuota from '../resources/images/servicesIcons/LavoriInQuota.png';
import Pavimentazioni from '../resources/images/servicesIcons/Pavimentazioni.png';
import Pitturazioni from '../resources/images/servicesIcons/Pitturazioni.png';
import Potatura from '../resources/images/servicesIcons/Potatura.png';
import Ristrutturazioni from '../resources/images/servicesIcons/Ristrutturazioni.png';
import {useEffect, useState} from "react";
import {useLoad} from "../store/LoadProvider";

const Loader = (props) => {
    const {palette} = useTheme();
    const load = useLoad();
    const transitionDuration = 600;
    const [isLoading, setIsLoading] = useState(true);
    const [opacity, setOpacity] = useState(1);

    useEffect(() => {
        if (load.isLoading) {
            setOpacity(1);
            setIsLoading(true);
        } else {
            setOpacity(0)
            setTimeout(() => {
                setIsLoading(false);
            }, transitionDuration);
        }
    }, [load.isLoading]);

    return <>
        {isLoading && <Stack
            position='fixed'
            zIndex={9999}
            width='100dvw'
            height='100dvh'
            top={0}
            left={0}
            alignItems='center'
            justifyContent='center'
            sx={{
                opacity,
                background: palette.dark.main,
                transition: `opacity ease-out ${transitionDuration / 1000}s`
            }}
        >
            <Box>
                <Carousel
                    height={100}
                    sx={{width: 100}}
                    interval={700}
                    animation='slide'
                    duration={400}
                    stopAutoPlayOnHover={false}
                    swipe={false}
                    indicators={false}
                    navButtonsAlwaysInvisible={true}
                >
                    <img src={Costruzioni} height={100} />
                    <img src={Intonaci} height={100} />
                    <img src={LavoriInQuota} height={100} />
                    <img src={Pavimentazioni} height={100} />
                    <img src={Pitturazioni} height={100} />
                    <img src={Potatura} height={100} />
                    <img src={Ristrutturazioni} height={100} />
                </Carousel>
            </Box>
            <Typography variant='h1' mt={3} fontSize='1rem' sx={{
                transform: 'scaleX(0.7)'
            }}>Caricamento in corso</Typography>
        </Stack>}
    </>
}

export default Loader;