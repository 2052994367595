import {createContext, useContext, useState} from "react";

const LoadContext = createContext();

const LoadProvider = ({children}) => {
    const [isLoading, setIsLoading] = useState(true);
    const start = Date.now();

    const hideLoader = async () => {
        await new Promise (resolve => {
            if (Date.now() - start > 1000){
                return resolve();
            }

            setTimeout(() => resolve(), 1000);
        })
        document.querySelector('html').classList.remove('no-scroll')
        setIsLoading(false);
    }

    const showLoader = () => {
        document.querySelector('html').classList.add('no-scroll')
        setIsLoading(true);
    }

    return <LoadContext.Provider value={{isLoading, hideLoader, showLoader}}>{children}</LoadContext.Provider>
}

export default LoadProvider;

export const useLoad = () => {
    return useContext(LoadContext);
}