export  const menuItems = [
    {
        target: 'aboutUs',
        title: 'Chi siamo',
    },
    {
        target: 'services',
        title: 'I nostri servizi'
    },
    {
        target: 'gallery',
        title: "Le nostre opere"
    },
    {
        target: 'contacts',
        title: "Contatti"
    }
];