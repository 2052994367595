import {createTheme, PaletteColorOptions} from "@mui/material";

declare module '@mui/material/styles' {
    interface Palette {
        pink: PaletteColorOptions;
        dark: PaletteColorOptions;
        blue: PaletteColorOptions;
        yellow: PaletteColorOptions;
        deepPurple: PaletteColorOptions;
        white: PaletteColorOptions;
        transparent: PaletteColorOptions;
    }
    interface PaletteOptions {
        pink: PaletteColorOptions;
        dark: PaletteColorOptions;
        blue: PaletteColorOptions;
        yellow: PaletteColorOptions;
        deepPurple: PaletteColorOptions;
        white: PaletteColorOptions;
        transparent: PaletteColorOptions;
    }
}

const customTheme = createTheme({
    typography: {
        allVariants: {
            color: '#f5f5f5'
        },
        fontFamily: 'sans-serif',
        h1: {
            fontFamily: 'Sofachrome',
            fontSize: '2rem',
            color: '#dfde0e',
            textShadow: '1px 1px 0 #5ec9df, -1px -1px 0 #df0ae0'
        },
        h3: {
            fontFamily: 'Sofachrome',
            fontSize: '1.7em',
            lineHeight: '1.7em',
            transform: 'scaleX(0.7)',
            color: '#5ec9df',
            textShadow: '1px 1px 0 #dfde0e, -1px -1px 0 #df0ae0'
        },
        h4: {
            fontSize: '1.7rem'
        },
        body1: {
            fontSize: '1.3rem',
        }
    },
    palette: {
        pink: {
            main: '#e00ae1',
            light: '#f529f5',
            dark: '#c309c3',
            contrastText: '#ffffff'
        },
        blue: {
            main: '#5ec9df',
            light: '#86d6e6',
            dark: '#24a2bb',
            contrastText: '#ffffff'
        },
        yellow: {
            main: '#dfde0e',
            light: '#f1f13f',
            dark: '#bebe0c',
            contrastText: '#000000'
        },
        deepPurple: {
            main: '#331e38',
            light: '#5f3969',
            dark: '#241528',
            contrastText: '#ffffff'
        },
        dark: {
            main: '#191716',
            light: '#2B2826',
            dark: '#0B0A0A',
            contrastText: '#f5f5f5'
        },
        transparent: {
            main: '#ffffff00',
            light: '#ffffff10',
            dark: '#ffffff20',
            contrastText: '#333333'
        },
        white: {
            main: '#f5f5f5',
            light: '#fdfdfd',
            dark: '#dedede',
            contrastText: '#191716',
        }
    }
})

export default customTheme;